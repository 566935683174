<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept :isFirstValue="false" type="search" label="LBLDEPT" name="deptCd" v-model="searchParam.deptCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-field label="대상자" name="userId" v-model="searchParam.userId" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            label="최근검진 기간"
            name="checkupRecentPeriod"
            v-model="searchParam.checkupRecentPeriod"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            label="검진예정 기간"
            name="checkupSchedulePeriod"
            v-model="searchParam.checkupSchedulePeriod"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="userTable"
      title="대상자별 도래 현황 목록"
      tableId="userTable"
      :columns="grid.columns"
      :merge="grid.merge"
      :data="grid.data"
    >
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='checkupComeDate'">
          <span>
            {{props.row.nextDate}}
            <q-badge v-if="props.row.checkTypeCd === 'batchAfter'" color="grey" label="X"/> 
            <q-badge v-else-if="props.row.checkupComeDate == -9999" color="orange" label="업로드 필요"/>
            <q-badge v-else-if="props.row.checkupComeDate == -8888" color="green" :label="props.row.checkupRecentBatchAfterDate + '에 배치후 검진 받음'"/>
            <q-badge v-else-if="props.row.checkupComeDate <= 7 && props.row.checkupComeDate >= 1" color="negative" :label="Math.abs(props.row.checkupComeDate)+'일전'" class="blinking" />
            <q-badge v-else-if="props.row.checkupComeDate <= 0" color="primary" label="도래했습니다." class="blinking" />
          </span>
        </template>
      </template>
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'check-up-status',
  data() {
    return {
      searchParam: {
        plantCd: null,
        deptCd: '',
        userId: '',
        checkupRecentPeriod: [],
        checkupSchedulePeriod: [],
      },
      grid: {
        merge: [
          { index: 0, colName: 'userId' },
          { index: 1, colName: 'userId' },
          { index: 2, colName: 'userId' },
          { index: 3, colName: 'userId' },
          // { index: 4, colName: 'userId' },
        ],
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            style: 'width: 80px',
            label: 'LBLPLANT',
            align: 'center',
            sortable: true,
            fix: true,
          },
          {
            name: 'deptName',
            field: 'deptName',
            style: 'width: 100px',
            label: 'LBLDEPT',
            align: 'center',
            sortable: true,
            fix: true,
          },
          {
            name: 'empNo',
            field: 'empNo',
            label: '사번',
            style: 'width: 80px',
            align: 'center',
            sortable: true,
            fix: true,
          },
          {
            name: 'userName',
            field: 'userName',
            style: 'width: 100px',
            label: '대상자',
            align: 'center',
            sortable: true,
            fix: true,
          },
          // {
          //   name: 'age',
          //   field: 'age',
          //   style: 'width: 60px',
          //   label: '연령',
          //   align: 'right',
          //   sortable: true,
          // },
          // {
          //   name: 'enterDate',
          //   field: 'enterDate',
          //   label: '입사일',
          //   align: 'center',
          //   style: 'width: 100px',
          //   sortable: false,
          // },
          // {
          //   name: 'longevityCount',
          //   field: 'longevityCount',
          //   label: '근속년수',
          //   align: 'right',
          //   style: 'width: 60px',
          //   sortable: false,
          // },
          {
            name: 'checkTypeName',
            field: 'checkTypeName',
            label: '검진구분',
            style: 'width: 80px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'checkupRecentDate',
            field: 'checkupRecentDate',
            label: '최근 검진일',
            style: 'width: 120px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'checkupScheduleDate',
            field: 'checkupScheduleDate',
            label: '검진 예정일',
            style: 'width: 120px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'checkupComeDate',
            field: 'checkupComeDate',
            label: '검진일 도래',
            align: 'center',
            type: 'custom',
            style: 'width: 120px',
            sortable: true,
          },
        ],
        data: [],
      },
      listUrl: '',
      editable: true,
      isSave: false,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: true,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      // api scope
      this.listUrl = selectConfig.hea.checkup.status.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
  }
};
</script>
